<script setup lang="ts">
import { ref, computed, reactive, onMounted } from 'vue'
import { Carousel } from 'bootstrap'
import { root } from '@root/http-common'

const carousel = ref<Carousel>()
const email = ref<string>()
const password = ref<string>()
const response = ref<string>()
const otpAttempt = ref<number>()
const otpRequired = ref<boolean>(false)
const rememberMe = ref<number>(0)

const state = reactive({
  carousel,
  email,
  password,
  otpAttempt,
  otpRequired,
  response,
  rememberMe
})

const authToken = computed((): string | undefined => {
  const el = document.querySelector('meta[name=csrf-token]')
  if (el) return el.getAttribute('content')!
})

onMounted(() => {
  state.carousel = new Carousel('#signInCarousel', {
    interval: false,
    keyboard: false,
    ride: false,
    touch: false,
    wrap: false
  })
})

const checkForOtp = (): void => {
  if (!email.value && !password.value) return

  root.post('/api/v4/users/sign_in', { email: email.value, password: password.value, route_to: 'super' })
    .then((res) => {
      if (res.status !== 401) {
        state.response = undefined
        state.otpRequired = res.data.otp_required
        if (otpRequired.value) carousel.value?.to(1)
        else {
          console.log(res.data)
          carousel.value?.to(3)
        }
      }
    })
    .catch((err) => {
      state.response = err.response.data.response
    })
}

const receiveOtp = (): void => {
  if (!email.value && !password.value) return

  root.post('/api/v4/users/sign_in', { email: email.value, password: password.value, route_to: 'otp' })
    .then((res) => {
      if (res.status == 200) {
        state.response = undefined
      }
    })
    .catch((err) => {
      state.response = err.response.data.response
    })
}

const submit = (): void => {
  if (!email.value && !password.value) return

  const form = document.getElementById('new_user') as HTMLFormElement
  form.submit()
}
</script>

<template>
  <div
    id="signInCarousel"
    class="carousel slide"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div v-if="response" class="alert alert-danger d-flex align-items-center" role="alert">
          <div>{{ response }}</div>
        </div>

        <form>
          <label>Email address</label>
          <input
            id="email"
            v-model="email"
            placeholder="Email address"
            class="form-control mb-3"
            type="text"
            @keyup.enter="checkForOtp()"
          >

          <label>Password</label>
          <input
            id="password"
            v-model="password"
            placeholder="Password"
            class="form-control mb-3"
            type="password"
            @keyup.enter="checkForOtp()"
          >

          <div class="center">
            <button
              class="btn btn-primary me-2"
              @click.prevent="checkForOtp()"
            >
              Sign in
            </button>
            <a
              class="btn btn-outline-dark"
              href="/d/password/new"
            >Reset password</a>
          </div>
        </form>
      </div>

      <div class="carousel-item">
        <label>OTP code</label>
        <input
          v-model="otpAttempt"
          placeholder="OTP code"
          class="form-control mb-3"
          type="text"
        >

        <fieldset class="form-group boolean optional user_remember_me">
          <div class="form-check">
            <input
              v-model="rememberMe"
              class="form-check-input boolean optional"
              type="checkbox"
            >
            <label class="form-check-label boolean optional">Remember this device for 30 days</label>
          </div>
        </fieldset>
        <div class="center">
          <button
            class="btn btn-primary me-2"
            @click.prevent="submit()"
          >
            Sign in
          </button>
          <a
            class="btn btn-outline-dark"
            href="/d/password/new"
          >
            Reset password
          </a>
        </div>
      </div>

      <div class="carousel-item">
        <label>Authenticator passcode required</label>
        <p>You'll need the six-digit code from your authenticator app to continue. Or, you can request an email with a one time password which will go to the email address of your username.</p>
        <input
          v-model="otpAttempt"
          placeholder="OTP code"
          class="form-control mb-3"
          type="text"
        >

        <fieldset class="form-group boolean optional user_remember_me">
          <div class="form-check">
            <input
              v-model="rememberMe"
              class="form-check-input boolean optional"
              type="checkbox"
            >
            <label class="form-check-label boolean optional">Remember this device for 30 days</label>
          </div>
        </fieldset>

        <div class="center">
          <button
            class="btn btn-outline-dark me-2"
            @click.prevent="receiveOtp()"
          >
            Receive OTP code via email
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="submit()"
          >
            Sign in
          </button>
        </div>
      </div>

      <div class="carousel-item">
        Please click sign in to proceed.
        <button
          class="btn btn-primary"
          @click.prevent="submit()"
        >
          Sign in
        </button>
      </div>
    </div>

    <div class="shrunk">
      <form
        id="new_user"
        action="/d/sign_in"
        accept-charset="UTF-8"
        method="post"
      >
        <input
          v-model="authToken"
          name="authenticity_token"
          type="hidden"
        >
        <input
          v-model="email"
          name="user[email]"
          type="text"
        >
        <input
          v-model="password"
          name="user[password]"
          type="password"
        >
        <input
          v-model="otpAttempt"
          name="user[otp_attempt]"
          type="text"
        >
        <input
          v-model="rememberMe"
          type="hidden"
          name="user[remember_me]"
        >
        <button
          id="new_user_submit"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #new_user { display: none; }
  .shrunk { visibility: hidden; max-height: 1px; }
</style>
