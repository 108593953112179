import { createApp } from 'vue'

import SessionsApp from '@components/SessionsApp.vue'
import { BugsnagConfig } from '@root/bugsnag-config'

document.addEventListener('DOMContentLoaded', () => {
  const sessionsEl = document.getElementById('sessions-app')

  if (sessionsEl) {
    const app = createApp(SessionsApp)

    if (process.env.ENV_NAME !== 'development') {
      const bugsnagVue = BugsnagConfig.init()
      app.use(bugsnagVue)
    }

    app.mount('#sessions-app')
  }
})
